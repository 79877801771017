import React from 'react';
// UI
import { Box, Grid, useTheme, Typography, useMediaQuery } from '@mui/material';
import BackgroundImage from '../../assets/heros/back-home.webp';
import BestImage from '../../assets/landingAssets/best-icon.svg';
import ConciergeImage from '../../assets/landingAssets/concierge-icon.svg';
import GarantizadoImage from '../../assets/landingAssets/garantizado-icon.svg';
//import SeatImage from '../../assets/landingAssets/Seat.webp';
// Components & Utils
import SocialMedia from '../shared/SocialMedia';
// import AppsStores from '../shared/AppsStores';
import { CTAHero } from './CTAHero';

const LandingTitle = ({isM = false}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return(
    <>
      <Typography
        variant='h1'
        gutterBottom
        sx={{
          fontSize: { xs: '30px', sm: '45px', md: '65px', lg: '80px' },
          filter: 'drop-shadow(0px 0px 16px black)',
          textAlign: 'center',
          marginBottom: '40px'
        }}>
        <span style={{fontWeight: '400'}}>Palcos, suites y boletos para </span> 
      los <b style={{color: '#26d07c'}}>mejores eventos</b>
      </Typography>
      <CTAHero isFullWidth={isM} textCta='Selecciona un evento' />
      <Box display={{xs: 'block', md: 'none', lg: 'none'}} marginTop='2em'>
        <SocialMedia />
      </Box>
      <Grid container spacing={1} position='relative' marginTop='3em' textAlign='center'>
        <Grid item xs={4} md={4} lg={4} textAlign='center'>
          <Typography variant='p' fontSize={{xs: '10px', sm: '12px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
            <img src={BestImage} alt="Best Img" style={{ width: isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Los mejores eventos
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4} textAlign='center'>
          <Typography variant='p' fontSize={{xs: '10px', sm: '12px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
            <img src={ConciergeImage} alt="Best Img" style={{ width: isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Concierge Oasis 24/7
          </Typography>
        </Grid>
        <Grid item xs={4} md={4} lg={4} textAlign='center'>
          <Typography variant='p' fontSize={{xs: '10px', sm: '12px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
            <img src={GarantizadoImage} alt="Best Img" style={{ width: isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Boletos 100% garantizados
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

/*const LandingTitle = ({ isMobile = false }) => (
  <>
    <Typography
      variant='h1'
      gutterBottom
      sx={{
        fontSize: { xs: '30px', sm: '45px', md: '65px', lg: '80px' },
        filter: 'drop-shadow(0px 0px 16px black)',
        textAlign: 'center',
        marginBottom: '40px'
      }}>
      <span style={{fontWeight: '400'}}>Palcos, suites y boletos para </span> 
      los <b style={{color: '#26d07c'}}>mejores eventos</b>
    </Typography>
    <CTAHero isFullWidth={isMobile} textCta='Selecciona un evento' />
    <Box display={{xs: 'block', md: 'none', lg: 'none'}} marginTop='2em'>
      <SocialMedia />
    </Box>
    <Grid container spacing={1} position='relative' marginTop='3em' textAlign='center'>
      <Grid item xs={4} md={4} lg={4} textAlign='center'>
        <Typography variant='p' fontSize={{xs: '10px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
          <img src={BestImage} alt="Best Img" style={{ width: !isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Los mejores eventos
        </Typography>
      </Grid>
      <Grid item xs={4} md={4} lg={4} textAlign='center'>
        <Typography variant='p' fontSize={{xs: '10px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
          <img src={ConciergeImage} alt="Best Img" style={{ width: !isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Concierge Oasis 24/7
        </Typography>
      </Grid>
      <Grid item xs={4} md={4} lg={4} textAlign='center'>
        <Typography variant='p' fontSize={{xs: '10px', md: '18px', lg: '20px'}} fontFamily='MuseoModerno'>
          <img src={GarantizadoImage} alt="Best Img" style={{ width: !isMobile ? '20px' : '35px', verticalAlign: 'middle' }} /> Boletos 100% garantizados
        </Typography>
      </Grid>
    </Grid>
  </>
);*/

/**
 * @function HeroBanner
 * @author RCH010
 * @version 1.0
 * @description Banner used on landing.
 * @returns {JSX}
 */
const HeroBanner = (props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  //const isMedium = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Box
        {...props}
        sx={{
          minHeight: isMobile ? '22em' : '40em',
          height: isMobile ? '45em' : '85vh',
          width: '100%',
          backgroundImage: `url(${BackgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'scroll',
          backgroundPosition: 'center bottom',
          backgroundSize: 'cover',
          position: 'relative',
          zIndex: 1,
          marginTop: '4.5em'
        }}>
        <Box
          sx={{
            mx: 'auto',
            display: 'flex',
            height: '100%',
            width: '100%',
            alignItems: isMobile ? 'center' : 'none',
            maxWidth: theme.breakpoints.values.lg,
            px: 3,
          }}>
          <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            justifyContent='space-between'
            alignItems={isMobile ? 'center' : 'flex-end'}
            sx={{ mb: isMobile ? 0 : 2 }}
            spacing={isMobile ? 5 : 0}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  textAlign: 'center',
                  //display: 'flex',
                  height: '100%',
                  width: '100%',
                  alignItems: 'center',
                  //justifyContent: 'center',
                  //flexDirection: 'column',
                  px: isMobile ? 4 : 0,
                  //mb: 10,
                }}>
                <LandingTitle />
              </Box>
            </Grid>
            {/*<Grid item md={4}>
              <Box
                sx={{
                  ml: isMedium ? '-2em' : '-5em',
                  maxWidth: isMobile ? '15em' : 'unset',
                  mb: isMobile ? '-8em' : '-5em',
                }}>
                <img
                  loading='eager'
                  draggable={false}
                  alt=''
                  src={SeatImage}
                  style={{
                    width: '100%',
                  }}
                />
              </Box>
            </Grid>*/}
            <Box sx={{ ml: 'auto' }}>{!isMobile && <SocialMedia />}</Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HeroBanner;
