import { httpRequest, httpRequestNewLambda } from '../utils/util';

export const getAllEvents = async () => {
  const res = await httpRequest('app/events/home');
  const data = res.data;

  /// TODO: define error codes
  if (res.success && data) return data;
  if (!res.success)
    throw new Error(
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
    );
  // any other is an error
  throw new Error(
    'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  );
};

export const getAllEventsNewLambda = async () => {
  const res = await httpRequestNewLambda();
  const data = res.data;

  return data;

  /// TODO: define error codes
  // if (res.success && data) return data;
  // if (!res.success)
  //   throw new Error(
  //     'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
  //   );
  // // any other is an error
  // throw new Error(
  //   'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  // );
};

export const getEvent = async (id) => {
  const res = await httpRequest(`app/events/${id}`);
  const data = await res.data;
  /// TODO: define error codes
  if (res.success && data) return data;
  if (!res.success)
    throw new Error(
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
    );
  // any other is an error
  throw new Error(
    'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  );
};

export const getEventBoxes = async (id) => {
  const res = await httpRequest(`app/boxes?eventId=${id}`);
  const data = await res.data;
  /// TODO: define error codes
  if (res.success && data) return data;
  if (!res.success)
    throw new Error(
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
    );
  // any other is an error
  throw new Error(
    'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  );
};

export const getBoxEvent = async (boxID, eventID) => {
  const res = await httpRequest(`app/boxes/${boxID}?event=${eventID}`);
  const data = await res.data;
  /// TODO: define error codes
  if (res.success) return data;
  if (!res.success)
    throw new Error(
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
    );
  // any other is an error
  throw new Error(
    'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  );
};

export const getEvents = async ({
  page,
  size,
  latitude,
  longitude,
  venueId,
  all,
  startsAt,
  state,
  category,
  cityId,
  boxId,
  search,
}) => {
  const res = await httpRequest(
    `app/events?${search ? `search=${search}` : ''}&${page ? `page=${page}` : ''
    }&${category ? `category=${category}` : ''}&${size ? `size=${size}` : ''}&${latitude ? `latitude=${latitude}` : ''
    }&${longitude ? `longitude=${longitude}` : ''}&${venueId ? `venueId=${venueId}` : ''
    }&${all ? `all=${all}` : ''}&${startsAt ? `startsAt=${startsAt}` : ''}&${state ? `state=${state}` : ''
    }&${cityId ? `cityId=${cityId}` : ''}&${boxId ? `boxId=${boxId}` : ''}`
  );
  const data = await res.data;
  /// TODO: define error codes
  if (res.success) return data;
  if (!res.success)
    throw new Error(
      'Tenemos un problema con nuestro servicio por favor intenta de nuevo más tarde'
    );
  // any other is an error
  throw new Error(
    'Tenemos un problema con nuestro servicio, por favor intenta de nuevo más tarde.'
  );
};
