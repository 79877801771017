import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Grid,
  Link as MUILink,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import OasisLogo from '../../assets/logo/Logo.svg';
import OaseasLogo from '../../assets/logo/logo-oaseas.svg';
import {
  footerMenuOptions,
  landingOptions,
  menuOptions,
} from '../../utils/menuOptions';
import { Link, useNavigate } from 'react-router-dom';
import { contactData } from '../../utils/contactData';
import SocialMedia from '../shared/SocialMedia';
import { openExternalLink } from '../../utils/util';

const footerStyles = {
  sx: { color: (currTheme) => currTheme.palette.grey[500] },
  variant: 'body2',
  underline: 'hover',
  component: Link,
  textAlign: 'center',
};

const Divider = () => (
  <Typography
    sx={{
      fontWeight: '100',
      fontSize: '2rem',
      mx: 2,
      color: (currTheme) => currTheme.palette.grey[500],
      display: 'inline-block',
    }}>
    |
  </Typography>
);

// TODO: Aviso de privacidad

const Footer = ({ displayFooter }) => {
  const navigate = useNavigate();
  // Return empty fragment if displayFooter is false

  const handleItemClick = (option) => {
    if (landingOptions.includes(option.id)) {
      window.location.href = option.path;
      return;
    }

    if (option.isExternal) {
      openExternalLink(option.id);
      return;
    }

    navigate(option.path);
  };

  const handleVerMas = () => {
    const mostrar = document.getElementById('seo');
    const ver = document.getElementById('vermas');
    if (ver.innerHTML === '<b>Ver más</b>') {
      ver.innerHTML = '<b>Ver menos</b>';
    } else {
      ver.innerHTML = '<b>Ver más</b>';
    }
    mostrar.toggleAttribute('hidden');
  };

  if (!displayFooter) return <></>;

  return (
    <footer>
      <Box
        sx={{
          bgcolor: (currTheme) => currTheme.palette.footerBackground,
          width: '100%',
          height: '100%',
          pt: 10,
          pb: 5,
        }}>
        <Box
          sx={{
            maxWidth: (currTheme) => currTheme.breakpoints.values.lg,
            mx: 'auto',
          }}>
          <Grid container align='center' justifyContent='center' spacing={5}>
            <Grid item xs={12} md={12} lg={12} xl={3} sx={{}}>
              <Button
                disableRipple
                aria-label='OasisApp'
                onClick={() => {
                  navigate('/');
                }}>
                <img
                  src={OasisLogo}
                  alt='OasisApp'
                  style={{ margin: 'auto' }}
                />
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant='body2'
                gutterBottom
                align='center'
                sx={{
                  color: (currTheme) => currTheme.palette.grey[300],
                }}>
                Menú
              </Typography>
              <List dense>
                {footerMenuOptions.map((option) => (
                  <ListItem
                    key={option.id}
                    sx={{
                      px: '2em',
                      py: '0',
                    }}>
                    <ListItemText>
                      <Button
                        fullWidth
                        color='inherit'
                        variant='text'
                        aria-label={option.label}
                        sx={{
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        }}
                        onClick={() => {
                          handleItemClick(option);
                        }}>
                        {option.label}
                      </Button>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant='body2'
                gutterBottom
                align='center'
                sx={{
                  color: (currTheme) => currTheme.palette.grey[300],
                }}>
                Contacto
              </Typography>
              <List dense>
                {Object.keys(contactData).map((contactKey) => (
                  <ListItem
                    key={contactKey}
                    sx={{
                      px: '2em',
                      py: '0',
                    }}>
                    <ListItemIcon sx={{ minWidth: 'fit-content' }}>
                      {contactData[contactKey].Icon}
                    </ListItemIcon>
                    <ListItemText>
                      <Button
                        fullWidth
                        color='inherit'
                        variant='text'
                        aria-label={contactData[contactKey].label}
                        sx={{
                          textTransform: 'none',
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        }}
                        onClick={() => {
                          window.open(contactData[contactKey].href, '_blank');
                        }}>
                        {contactData[contactKey].label}
                      </Button>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Typography
                variant='body2'
                gutterBottom
                align='center'
                sx={{
                  color: (currTheme) => currTheme.palette.grey[300],
                }}>
                ¡Síguenos!
              </Typography>
              <SocialMedia justifyContent='center' spacing={2} />
              <a href="https://oaseas.mx/" target="_blank" rel="noopener noreferrer">
                <img src={OaseasLogo} alt="Oaseas" style={{ margin: 'auto', width: '200px' }}/>
              </a>
            </Grid>
          </Grid>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ py: 3 }}>
            <MUILink {...footerStyles} to={menuOptions.TERMSANDCONITIONS.path}>
              Términos y condiciones
            </MUILink>
            <Divider />
            <MUILink {...footerStyles} to={menuOptions.PRIVACY.path}>
              {/** TODO: Aviso de privacidad */}
              Aviso de Privacidad
            </MUILink>
            <Divider />
            <Typography
              variant='body2'
              sx={{ color: (currTheme) => currTheme.palette.grey[500] }}>
              @{new Date().getFullYear()} Oasis
            </Typography>
          </Stack>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            sx={{ py: 3 }}>
            <Typography
              variant='body2'
              sx={{ color: (currTheme) => currTheme.palette.grey[500] }}>
              Bienvenido al mundo de renta de palcos a través de OasisApp...{' '}
              <span
                style={{ cursor: 'pointer' }}
                id='vermas'
                onClick={() => {
                  handleVerMas();
                }}>
                <b>Ver más</b>
              </span>
            </Typography>
          </Stack>
          <Box
            direction='row'
            justifyContent='center'
            alignItems='center'
            maxHeight={'190px'}
            overflow={'auto'}
            id='seo'
            color={'#9e9e9e'}
            fontSize={'1rem'}
            hidden
            sx={{ py: 3 }}>
            <p>
              Si eres un apasionado de los eventos deportivos, conciertos y
              experiencias en estadios de primer nivel, nuestra plataforma es tu
              puerta de entrada a la comodidad y el lujo. Descubre la magia de
              vivir los eventos desde la mejor ubicación, con la renta de palcos
              en el Azteca, en el Estadio Cuauhtémoc y muchos otros lugares
              emblemáticos en la Ciudad de México.
            </p>
            <p>
              OasisApp te ofrece la oportunidad única de disfrutar de la renta
              de palcos en el Azteca, el epicentro de la emoción deportiva y
              musical en México. Con nuestros exclusivos servicios, podrás
              acceder a la renta de palcos para conciertos y eventos deportivos
              en la CDMX de manera segura y confiable. Imagina la emoción de
              presenciar el Gran Premio de Fórmula 1 México desde la comodidad
              de tu propio palco, con acceso VIP y una vista privilegiada.
            </p>
            <p>
              La renta de palcos en la Arena CDMX es otra de las experiencias
              únicas que OasisApp pone a tu alcance. Nuestro servicio de Airbnb
              de palcos te permite encontrar el lugar perfecto para disfrutar de
              conciertos inolvidables. ¿Te gustaría vivir la emoción de un
              espectáculo en el Foro Sol? Con OasisApp, la renta de palcos en el
              Foro Sol se convierte en una experiencia sin igual.
            </p>
            <p>
              En OasisApp, entendemos la importancia de brindar seguridad y
              confianza a nuestros usuarios. Nuestra plataforma está diseñada
              para garantizar la autenticidad de tus boletos y protegerte contra
              fraudes. Con la renta de palcos por asiento, tienes la
              tranquilidad de saber que estás obteniendo un servicio de calidad
              y que cada detalle está cuidado para que tu experiencia sea
              inolvidable.
            </p>
            <p>
              Nuestro servicio concierge pre y post venta está a tu disposición
              para asegurarse de que cada momento sea perfecto. Desde la reserva
              de tu palco hasta el acompañamiento en el evento, estamos
              comprometidos a ofrecerte un servicio de excelencia.
            </p>
            <p>
              No te pierdas la oportunidad de vivir la emoción de los eventos en
              México desde la mejor ubicación. Descarga OasisApp y descubre la
              mejor forma de disfrutar de la renta de palcos en México. Sé parte
              de la exclusividad, la seguridad y el lujo que solo OasisApp puede
              ofrecerte. ¡Bienvenido al oasis de experiencias inolvidables!
            </p>
            <p>
            Oasisapp no tienes ninguna relación con Ticketmaster, Stadibox, o alguna boletera diferente. Nuestro servicio se centra en la renta de palcos para los mejores eventos conciertos, deportes y otros eventos de entretenimiento.
            </p>
          </Box>
        </Box>
      </Box>
    </footer>
  );
};

Footer.defaultProps = {
  displayFooter: true,
};

Footer.propTypes = {
  displayFooter: PropTypes.bool,
};

export default Footer;
