import externalLinks from './json/externalLinks.json';
import errorMessages from './json/errorMessages.json';

const API2baseURL = process.env.REACT_APP_NEW_API_BASE_URL;
const APIbaseURL = process.env.REACT_APP_API_BASE_URL;
const copomexURL = process.env.REACT_APP_COPOMEX_URL;

export const openExternalLink = (id) => {
  window.open(externalLinks[id], '_blank');
};

export const APITypes = {
  PIXAN: 'PIXAN',
  OASIS: 'OASIS',
  COPOMEX: 'COMPOMEX',
};

const apiTypesURLS = {
  [APITypes.PIXAN]: APIbaseURL,
  [APITypes.OASIS]: API2baseURL,
  [APITypes.COPOMEX]: copomexURL,
};

/**
 * @function scrollIntoRef(ref), recives optional arguments (behavior, blokc and inline) in object
 * @param ref numbert to format
 */
export const scrollIntoRef = (
  ref,
  { behavior = 'smooth', block = 'start', inline = 'nearest' } = {}
) => {
  ref.current.scrollIntoView({
    behavior: behavior,
    block: block,
    inline: inline,
  });
};

const defaultHTTPRequestData = {
  method: 'GET',
  body: undefined,
  headers: {
    'Content-Type': 'application/json',
  },
};

const getAuthToken = async () => {
  const jwt = appStorage.retrieve('jwt');
  if (jwt && jwt.expiresAt && new Date(jwt.expiresAt) < new Date()) {
    const { data } = await httpRequest(
      'auth/refresh',
      {
        body: { refreshToken: jwt.refreshToken },
        headers: { Authorization: `Bearer ${jwt.token}` },
        method: 'POST',
      },
      'PIXAN',
      false
    );
    jwt.token = data.token;
    appStorage.save('jwt', {
      token: data.token,
      refreshToken: data.refreshToken,
      expiresAt: data.expiresAt,
    });
  }
  if (jwt) return { Authorization: `Bearer ${jwt.token}` };
  return {};
};

const checkSuccess = async (response) => {
  if (response && response.status === 'error') {
    // console.log('KEYS', Object.keys(response.messages));
    if (
      Object.keys(response.messages).includes('invalid_token') ||
      Object.keys(response.messages).includes('401')
    ) {
      // console.log('NOT AUTHENTICATED');
      appStorage.clear();
      // TODO: Logout
    }
    return response;
    // TODO Catch errors
    // throw new Error(JSON.stringify(response.messages));
  }
  return response;
};

export const httpRequest = async (
  resource,
  requestOptions = defaultHTTPRequestData,
  api = APITypes.OASIS,
  withToken = true
) => {
  const baseURL = apiTypesURLS[api];
  try {
    let authToken;
    if (withToken) {
      authToken = await getAuthToken();
    }
    const response = await fetch(`${baseURL}/${resource}`, {
      headers: {
        ...defaultHTTPRequestData.headers,
        ...(withToken && authToken),
      },
      body:
        requestOptions.method !== 'GET'
          ? JSON.stringify(requestOptions.body)
          : undefined,
      method: requestOptions.method,
    });
    const responseChecked = checkSuccess(response.json());
    return responseChecked;
  } catch (e) {
    return e?.response?.data || e;
  }
};

export const httpRequestNewLambda = async (
  resource,
  requestOptions = defaultHTTPRequestData,
  api = APITypes.OASIS,
  withToken = true
) => {
  try {
    const url = process.env.REACT_APP_API_URL_NEW_LAMBDA
    const res = await (await fetch(url)).json()
    return res
  } catch (e) {
    return e?.response?.data || e;
  }
};

export const appStorage = {
  save: (key, data) => localStorage.setItem(key, JSON.stringify(data)),
  retrieve: (key) => JSON.parse(localStorage.getItem(key)),
  clear: () => localStorage.clear(),
};

/**
 * loadScript
 * @param {string} url url of script to be loaded
 * @param {function} callback function to be executed when script is loaded
 */
export const loadScript = (url, callback) => {
  let script;
  const scripts = Array.from(document.querySelectorAll('script'));
  const existingScript = scripts.find((eScript) => eScript.src === url);
  if (existingScript) {
    script = existingScript;
  } else {
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }
};

/**
 * @function parseDate
 * @param {String} dateString date in a string
 * @param {String} type dayMonthYear | dayMonthYearTime | time
 * @returns {String}
 */
export const parseDate = (dateString = null, type = '') => {
  switch (type) {
    case 'dayShortMonthYear': // "7 jun 2022"
      return new Date(dateString).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    case 'dayLongMonthYear': // "7 de junio de 2022"
      return new Date(dateString).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    case 'dayMonthYearTime': // "7 jun 2022 02:18 p. m."
      return new Date(dateString?.includes('.000Z') ? dateString : `${dateString}.000Z`).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h12',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
      });
    case 'dayMonthYearTimeFixedTimezone': // "7 jun 2022 02:18 p. m."
      return new Date(dateString?.includes('.000Z') ? dateString : `${dateString}.000Z`).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hourCycle: 'h12',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: "-06:00",
      });
    case 'dayLongMonthYearTime': // "7 de junio de 2022, 02:18 p. m."
      return new Date(dateString).toLocaleDateString('es-MX', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hourCycle: 'h12',
        hour: '2-digit',
        minute: '2-digit',
      });
    case 'time': // "2:18 p. m."
      return new Date(dateString?.includes('.000Z') ? dateString : `${dateString}.000Z`).toLocaleTimeString('es-MX', {
        hour: 'numeric',
        minute: 'numeric',
        hourCycle: 'h12',
        timeZone: "-06:00",
      });
    case 'yyyy-mm-dd':
      return new Date(dateString).toISOString().split('T')[0];
    default:
      return new Date(dateString).toLocaleDateString();
  }
};

/**
 * @function Money formater, returns string
 * @param {Number} number to format
 * @param {Object} options
 * @param {String} options.currency currency String (use enum)
 * @param {Bool} options.moneySign true by default
 * @returns {String}
 */
export const moneyFormat = (
  number = 0,
  { currency = 'MXN', moneySign = true } = {}
) => {
  let value = number.toFixed(2);
  value = isNaN(value) ? 0 : value;
  return `${moneySign ? '$' : ''}${value
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${currency ? ` ${currency}` : ''}`;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

export const errorResponse = ({ code, message }) => {
  const errorMessage = errorMessages[code] || null;
  const labelMessage = message || errorMessage;
  return {
    success: false,
    code: code,
    message: labelMessage,
  };
};

export const getRandomValue = (min = null, max = null) => {
  if (min && max) {
    return Math.floor(min + Math.random() * (max - min + 1));
  } else Math.random();
};

// Write a function that takes two numbers (min and max) as arguments
// Return an array of numbers in the range min to max
// Extra points if you avoid using for loops.
/**
 * @function createRange
 * @description creates an array from min to max
 * example: (-1, 4) => [-1, 0, 1, 2, 3, 4]
 * @param {Number} min
 * @param {Number} max
 * @returns {Array<Number>}
 */
export const createRange = (min, max) => {
  return Array.from({ length: max - min }, (_, k) => min + k);
};
