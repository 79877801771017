import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography, Skeleton } from '@mui/material';
import { useSearchEvents } from '../../hooks/requests';
import EventCardSearch from '../../components/cards/EventCardSearch';
import EmptyState from '../../components/shared/EmptyState';
import { useNavigate } from 'react-router-dom';
// Components & Utils
// UI

/**
 * @function EventsBySearch
 * @author RCH010
 * @version 1.0
 * @description a description of the component
 * @param {String} something short description of prop
 * @returns {JSX}
 */
const EventsBySearch = ({ search, category, categoryName }) => {
  const [events, setEvents] = useState(null);
  const [setIsError] = useState(false);
  const navigate = useNavigate();

  useSearchEvents(
    { search, category },
    {
      // enabled: Boolean(search),
      onSuccess: (data) => {
        setEvents(data);
      },
      onError: (error) => {
        setIsError(true);
      },
    }
  );

  return (
    <>
      <Box
        sx={{
          px: 3,
          maxWidth: 'lg',
          mx: 'auto',
          zIndex: 1,
          position: 'relative',
        }}>
        <Typography variant='h2' sx={{ mb: 2 }}>
          {search} {categoryName}
        </Typography>
        {events ? (
          events.length > 0 ? (
            <Grid container rowSpacing={5} columnSpacing={4}>
              {events.map((event) => (
                <Grid item key={event.id} xs={12} sm={6} md={4}>
                  <EventCardSearch
                    {...event}
                    cardRootStyles={{ width: '100%', maxWidth: '100%' }}
                  />
                </Grid>
              ))}
            </Grid>
          ) : (
            <EmptyState
              title='¡Oh no!'
              subtitle='No hemos encontrado eventos con coincidan con tu criterio de busqueda'
              buttonLabel='Ver eventos por categoria'
              onButtonClick={() => navigate('/events')}
            />
          )
        ) : (
          <Grid container rowSpacing={5} columnSpacing={4}>
            {[1, 2, 3, 4, 5, 6].map((item) => (
              <Grid item key={item} xs={12} sm={6} md={4}>
                <Skeleton
                  variant='rectangular'
                  sx={{
                    width: '100%',
                    height: {
                      xs: '13.25em',
                      sm: '13.4em',
                      md: '17.3em',
                      lg: '17.4em',
                    },
                    borderRadius: '16px',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </>
  );
};

EventsBySearch.defaultProps = {
  something: '',
};

EventsBySearch.propTypes = {
  something: PropTypes.string,
};

export default EventsBySearch;
